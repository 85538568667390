  /* Mobile */
  @media screen and (max-width: 768px) {

    #bodyTeste {
      font-family: Arial, Helvetica, sans-serif;
      background-image: linear-gradient(to right, #121b23, #0e4b61);
      color: white; 
      text-align: center; 
      padding: 20px; 
      margin: 0;
      padding-bottom: 25px;
    }

    #formTeste {
      color: white;
      position: absolute;
      top: 110px;
      left: 50%;
      transform: translateX(-50%);
      background-image: linear-gradient(to right, #121b23, #0e4b61);
      padding: 1%;
      border-radius: 10px;
      width: 96%;
      border: 2px solid #0c3951;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      justify-content: center;
    }

    .btnTeste,
    .btnVoltar,
    .btnEnviar,
    #btn-abrir-camera,
    #btn-upload,
    #submit {
      background-color: #fff;
      color: #0e1217;
      border: 1px solid #fff;
      opacity: 1;
      transition: 0.3s;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      text-align: center;
      cursor: pointer;
    }

    button.btn-danger-teste {
      background-color: #dc3545;
      color: #fff;
      border: 1px solid #dc3545;
      opacity: 1;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      margin-left: 3%;
    }

    .btn-fechar-camera {
      background-color: #fff;
      color: #0e1217;
      border: 1px solid #fff;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      transition: 0.3s;
      width: 99%;
    }

    #textareaTeste,
    .selectTeste,
    .inputTeste {
      border: 2px solid #0e4b61;
    }

    p#erro_email_teste,
    p#erro_campos_teste,
    p#upload-teste {
      color: red;
    }

    .labelTeste,
    .checkboxTeste,
    p.text-success-teste {
      color: #ffffff;
    }

    .btnTeste {
      max-width: 100%;
      width: 94%;
    }

    .btnVoltar,
    .btnEnviar,
    #btn-abrir-camera,
    #btn-upload,
    #submit {
      max-width: 40%;
      margin: 0 auto; 
      width: 99%;
    }
  
    .btnVoltar,
    .btnEnviar {
      margin-left: 7%;
    }

    .inputTeste {
      width: 95%;
    }

    #textareaTeste {
      margin-bottom: 3%; 
      width: calc(100% - 20px);
    }

    .selectTeste {
      width: 95%;
    }

    #msg-outros {
      margin-top: -90px;
    }

    #msg-tipo-2 {
      margin-top: -60px;
    }

    #msg-complementar {
      margin-top: 1px;
    }

    #msg-complementar-linha {
      margin-top: -85px;
    }

    .selectTeste,
    #msg-outros,
    #msg-tipo-2,
    #msg-complementar {
      margin-right: 3%;
    }

    .btnTeste,
    .btn-fechar-camera,
    .labelTeste,
    .inputTeste,
    .selectTeste,
    .checkboxTeste,
    p#erro_email_teste,
    p#erro_campos_teste,
    p#upload-teste,
    #btn-upload,
    #btn-abrir-camera,
    #btn-recaptcha-teste,
    #textareaTeste,
    #msg-outros,
    #msg-tipo-2,
    #msg-denuncia,
    #msg-complementar,
    #msg-complementar-linha {
      margin-left: 3%;
    }
  }

  /* Reset e Box Sizing */
* {
  box-sizing: border-box;
}

   /* Web */
   @media screen and (min-width: 769px) {

    #bodyTeste {
      font-family: Arial, Helvetica, sans-serif;
      background-image: linear-gradient(to right, #121b23, #0e4b61);
      color: white; 
      text-align: center; 
      padding: 20px; 
      margin: 0;
      padding-bottom: 25px;
    }
  
    #formTeste {
      background-image: linear-gradient(to right, #121b23, #0e4b61);
      padding: 1%;
      border-radius: 10px;
      width: 96%;
      border: 2px solid #0c3951;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      justify-content: center;
      max-width: 50%; 
      margin: 0 auto; 
      margin-top: 35px;
    }

    .btnVoltar,
    .btnEnviar,
    #btn-abrir-camera,
    #btn-upload,
    #submit {
      background-color: #fff;
      color: #0e1217;
      border: 1px solid #fff;
      opacity: 1;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      max-width: 23%;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      margin: 0 auto; 
      width: 99%;
    }

    button.btn-danger-teste {
      background-color: #dc3545;
      color: #fff;
      border: 1px solid #dc3545;
      opacity: 1;
    }

    p#erro_email_teste,
    p#erro_campos_teste,
    p#upload-teste {
      color: red;
    }

    p.text-success-teste,
    .labelTeste,
    .checkboxTeste {
      color: #ffffff;
    }

    #textareaTeste,
    .selectTeste,
    .inputTeste {
      border: 2px solid #0e4b61;
    }
    
    .btnTeste {
      background-color: #fff;
      color: #0e1217;
      border: 1px solid #fff;
      opacity: 1;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      max-width: 23%;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      margin-left: 38%;
      width: 99%;
      margin-bottom: 20px; 
    }

    #submit:hover {
      background-image: linear-gradient(to right, rgb(0, 80, 172), rgb(80, 19, 195));
    }

    .btnVoltar,
    .btnEnviar,
    .btn-fechar-camera,
    #btn-abrir-camera,
    #btn-upload,
    .inputTeste,
    #textareaTeste,
    .selectTeste
    {
      width: 80%; 
    }

    #msg-outros {
      text-align: justify;
      margin-top: -90px;
    }

    #msg-tipo-2 {
      text-align: justify;
      margin-top: -60px;
    }

    #msg-complementar {
      margin-top: 1px;
    }

    #msg-complementar-linha {
      margin-top: -85px;
    }

    #msg-outros,
    #msg-tipo-2,
    #msg-complementar {
      margin-right: 10%;
    }

    .btnVoltar,
    .btnEnviar,
    .btn-fechar-camera,
    .inputTeste,
    .selectTeste,
    .checkboxTeste,
    .labelTeste,
    button.btn-danger-teste,
    p#erro_email_teste,
    p#erro_campos_teste,
    p#upload-teste ,
    #btn-upload,
    #btn-abrir-camera,
    #textareaTeste,
    #btn-recaptcha-teste,
    #msg-outros,
    #msg-tipo-2,
    #msg-denuncia,
    #msg-complementar,
    #msg-complementar-linha {
     margin-left: 10%; 
    }
}
