.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: #16489f;
}


.navbar ul {
    display: flex;
    gap: 1rem;
}

.new-btn {
    font-weight: bold;
    border: 1px solid #FFF;
    padding: .5rem 1rem;
    border-radius: 1rem;
}

h2 {
    color: #FFF;
}

li.navbar {
    text-decoration: none;
}

label.labelDenuncia {
    color: #FFF;
    text-decoration: none;
}