  /* Mobile */
  @media screen and (max-width: 768px) {
    .btnAssedio {
      background-color: #fff;
      color: #0e1217;
      border: 1px solid #fff;
      opacity: 1;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      max-width: 100%;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      margin-left: 10px;
      width: 94%;
    }

    button.btn-danger-assedio {
      background-color: #dc3545;
      color: #fff;
      border: 1px solid #dc3545;
      opacity: 1;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      max-width: 100%;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      margin-left: 10px;
      width: 94%;
    }

    #btn-abrir-camera {
      opacity: 1;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      max-width: 100%;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      margin-left: 10px;
      width: 94%;
    }

    .inputAssedios {
      margin-left: 10px;
      width: 89%;
    }

    #textareaAssedio {
      margin-bottom: 10px; 
      margin-left: 10px;
      width: calc(100% - 20px);
    }

    .selectAssedio {
      width: 94%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  /* Web */
  @media screen and (min-width: 769px) {

    .btnAssedio {
      background-color: #fff;
      color: #0e1217;
      border: 1px solid #fff;
      opacity: 1;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      max-width: 100%;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      margin-left: 10px;
      width: 99%;
    }

    button.btn-danger-assedio {
      background-color: #dc3545;
      color: #fff;
      border: 1px solid #dc3545;
      opacity: 1;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      max-width: 100%;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      margin-left: 10px;
      width: 99%;
    }

    #btn-abrir-camera {
      opacity: 1;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      max-width: 100%;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      margin-left: 10px;
      width: 99%;
    }

    .inputAssedios {
      margin-left: 10px;
      width: 98%;
    }

    #textareaAssedio {
      margin-bottom: 10px; 
      margin-left: 10px;
      width: calc(100% - 20px);
    }

    .selectAssedio {
      width: 99%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  #formAssedio {
    font-family: Arial, Helvetica, sans-serif;
    background-image: linear-gradient(to right, #121b23, #0e4b61);
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  #tela1 {
    color: white;
    position: absolute;
    top: 125px;
    left: 50%;
    transform: translateX(-50%);
    background-image: linear-gradient(to top, #2a3f54, #2a3f54);
    padding: 1%;
    border-radius: 10px;
    width: 96%;
    border: 2px solid #0c3951;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    justify-content: center;
  }

  #submit {
    background-image: linear-gradient(to right, rgb(0, 92, 197), rgb(90, 20, 220));
    width: 100%;
    border: none;
    padding: 15px;
    color: white;
    font-size: 15px;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 20px;
  }

  #submit:hover {
    background-image: linear-gradient(to right, rgb(0, 80, 172), rgb(80, 19, 195));
  }

  .labelAssedio {
    color: #ffffff;
    margin-left: 10px;
  }

  p#erro_email_assedio,
  p#erro_campos_assedio,
  p#upload-assedio {
    color: red;
    margin-left: 10px;
  }

  #btn-recaptcha-Assedio {
    margin-left: 10px;
  }

  p.text-success-assedio {
    color: #ffffff;
  }

  #msg-outros {
    margin-left: 10px;
    margin-top: -70px;
  }

  #msg-tipo-2 {
    margin-left: 10px;
    margin-top: -70px;
  }

  #msg-complementar {
    margin-left: 10px;
    margin-top: 1px;
  }

  #msg-complementar-linha {
    margin-left: 15px;
    margin-top: -85px;
  }

