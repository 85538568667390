.denuncia h1{
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.5rem;
}

.post {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #fff;
}

.post h2,
.post p {
    margin-bottom: 1rem;
}

.post h2::first-letter{
    text-transform: uppercase;
}

.btn {
    background-color:  #fff;
    color: #0e1217;
    border: 1px solid #fff;
    opacity: 1;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    max-width: 100px;
    text-align: center;
    cursor:  pointer;
    transition: 0.3s;
}

.btn:hover{
    color:  #fff;
    background-color: #2a3f54;
}

.navDenuncia {
    background-color: #2a3f54;
    max-width: 100%;
}

label {
    color: #214770;
}

.App {
    background-color: white;
    height: 100;
}

body {
    max-width: 100%;
    margin: 0px;
    padding: 0px;
}

html {
    background-color: #fff;
}

select:hover{
    color:  #fff;
    background-color: #2a3f54;
}

.tituloNew {
    color: #2a3f54;
}

p#erro_email {
    color: red;
}

p#erro_campos {
    color: red;
}

span.text-danger {
    color: red;
}

p.text-danger {
    color: red;
}

p#upload {
    color: red;
}

p#erro_local {
    color: red;
}

p.text-success {
    color: #04613f;
}

button.btn-danger {
    color: #fff;
    background-color: #dc3545;
    
}

.btn-danger:hover {
    background-color: #d31f19;
}

button.confirmaDenuncia {
    background-color: #04aa6d;
    color: #fff;
}

.confirmaDenuncia:hover {
    background-color: #04613f;
}

label#lbl-denuncia {
    color: #04613f;
}
  