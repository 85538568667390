/* *{
  margin: 0;
  padding: 0;
  font-family: 'Blinker';
} */

ul{
  list-style: none;
}

a {
  text-decoration: none;
  color: #FFF;
  opacity: 0.7;
  transition: 0.3s;
}

a:hover{
  opacity: 1;
}

p{
  color: #fff;
}